/**
 * Page Politique de confidentialité Nader Abou Anas
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 */
import React from 'react';
import Layout from '../components/body/layout';
import Seo from '../components/seo';
import ContainerWithSideBarre from '../components/body/ContainerWithSideBarre';
import ContentLeft from '../components/body/ContentLeft';
import ContentRight from '../components/body/ContentRight';
import Container from '../components/body/Container';
import SideBar from '../components/marketing/sideBar/SideBar';
import StickyBox from 'react-sticky-box';

const Politique = ({ data }) => {
	return (
		<Layout title="POLITIQUE DE CONFIDENTIALITÉ">
			<Seo title="Nader Abou Anas | Politique de confidentialité" />

			<Container>
				<ContainerWithSideBarre>
					<StickyBox
						offsetTop={20}
						offsetBottom={20}
						className="w-full lg:w-8/12 stickyBox"
					>
						<ContentLeft backgroundDisplay={false}>
							<div>
								<div className="page" title="Page 1">
									<div className="layoutArea">
										<div className="column">
											<p>
												<strong>
													POLITIQUE DE CONFIDENTIALITÉ DU SITE :
													Dourous.net
												</strong>
											</p>
											<p>
												<strong>ARTICLE 1 : PRÉAMBULE</strong>
											</p>
											<p>
												Cette politique de confidentialité s’applique au
												site : Dourous.net.
											</p>
											<p>
												La présente politique de confidentialité a pour but
												d’exposer aux utilisateurs du site :
											</p>
											<p>
												La manière dont sont collectées et traitées leurs
												données à caractère personnel. Doivent être
												considérées comme données personnelles toutes les
												données étant susceptibles d’identifier un
												utilisateur. Il s’agit notamment du prénom et du
												nom, de l’âge, de l’adresse postale, l’adresse mail,
												la localisation de l’utilisateur ou encore son
												adresse IP ;
											</p>
											<p>
												Quels sont les droits des utilisateurs concernant
												ces données ;
											</p>
											<p>
												Qui est responsable du traitement des données à
												caractère personnel collectées et traitées ;
											</p>
											<p>
												A qui ces données sont transmises ;<br />
												Eventuellement, la politique du site en matière de
												fichiers “cookies”.
											</p>
											<p>
												Cette politique de confidentialité complète les
												mentions légales et les Conditions Générales
												d’Utilisation que les utilisateurs peuvent consulter
												à l’adresse ci-après :
											</p>
											<p>https://dourous.net/</p>
											<p>
												<strong>
													ARTICLE 2 : PRINCIPES GÉNÉRAUX EN MATIÈRE DE
													COLLECTE ET DE TRAITEMENT DE DONNÉES
												</strong>
											</p>
											<p>
												Conformément aux dispositions de l’article 5 du
												Règlement européen 2016/679, la collecte et le
												traitement des données des utilisateurs du site
												respectent les principes suivants :
											</p>
										</div>
									</div>
								</div>
								<div className="page" title="Page 2">
									<div className="layoutArea">
										<div className="column">
											<p>
												Licéité, loyauté et transparence : les données ne
												peuvent être collectées et traitées qu’avec le
												consentement de l’utilisateur propriétaire des
												données. A chaque fois que des données à caractère
												personnel seront collectées, il sera indiqué à
												l’utilisateur que ses données sont collectées, et
												pour quelles raisons ses données sont collectées ;
											</p>
											<p>
												Finalités limitées : la collecte et le traitement
												des données sont exécutés pour répondre à un ou
												plusieurs objectifs déterminés dans les présentes
												conditions générales d’utilisation ;
											</p>
											<p>
												Minimisation de la collecte et du traitement des
												données : seules les données nécessaires à la bonne
												exécution des objectifs poursuivis par le site sont
												collectées ;
											</p>
											<p>
												Conservation des données réduites dans le temps :
												les données sont conservées pour une durée limitée,
												dont l’utilisateur est informé. Lorsque cette
												information ne peut pas être communiquée,
												l’utilisateur est informé des critères utilisés pour
												déterminer la durée de conservation ;
											</p>
											<p>
												Intégrité et confidentialité des données collectées
												et traitées : le responsable du traitement des
												données s’engage à garantir l’intégrité et la
												confidentialité des données collectées.
											</p>
											<p>
												Afin d’être licites, et ce conformément aux
												exigences de l’article 6 du règlement européen
												2016/679, la collecte et le traitement des données à
												caractère personnel ne pourront intervenir que s’ils
												respectent au moins l’une des conditions ci-après
												énumérées :
											</p>
											<p>
												L’utilisateur a expressément consenti au traitement
												;
											</p>
											<p>
												Le traitement est nécessaire à la bonne exécution
												d’un contrat ;
											</p>
											<p>Le traitement répond à une obligation légale ;</p>
											<p>
												Le traitement s’explique par une nécessité liée à la
												sauvegarde des intérêts vitaux de la personne
												concernée ou d’une autre personne physique ;
											</p>
											<p>
												Le traitement peut s’expliquer par une nécessité
												liée à l’exécution d’une mission d’intérêt public ou
												qui relève de l’exercice de l’autorité publique ;
											</p>
											<p>
												Le traitement et la collecte des données à caractère
												personnel sont nécessaires aux fins des intérêts
												légitimes et privés poursuivis par le responsable du
												traitement ou par un tiers.
											</p>
											<p>
												<strong>
													ARTICLE 3 : DONNÉES À CARACTÈRE PERSONNEL
													COLLECTÉES ET TRAITÉES DANS LE CADRE DE LA
													NAVIGATION SUR LE SITE
												</strong>
											</p>
										</div>
									</div>
								</div>
								<div className="page" title="Page 3">
									<div className="layoutArea">
										<div className="column">
											<p>
												A. DONNÉES COLLECTÉES ET TRAITÉES ET MODE DE
												COLLECTE
												<br />
												Les données à caractère personnel collectées sur le
												site dourous.net sont les
											</p>
											<p>suivantes :</p>
											<ol>
												<li>Nom et Prénom</li>
												<li>Adresse e-mail</li>
												<li>Numéro de téléphone</li>
												<li>Adresse postale</li>
												<li>Pays</li>
												<li>Date de naissance</li>
												<li>Genre</li>
											</ol>
											<p>
												Ces données sont collectées lorsque l’utilisateur
												effectue l’une des opérations suivantes sur le site
												:
											</p>
											<ol>
												<li>
													Lorsque l’utilisateur{' '}
													<span style={{ textDecoration: 'underline' }}>
														<strong>s’inscrit à la</strong>{' '}
														<strong>newsletter</strong>
													</span>
												</li>
												<li>
													Lorsque l’utilisateur{' '}
													<span style={{ textDecoration: 'underline' }}>
														<strong>
															s’inscrit au programme de cours en ligne
														</strong>
													</span>
												</li>
												<li>
													Lorsque l’utilisateur{' '}
													<strong>
														<span
															style={{ textDecoration: 'underline' }}
														>
															s’abonne au programme de cours en ligne
														</span>
													</strong>
												</li>
											</ol>
											<p>
												Par ailleurs, lors d’un paiement sur le site, il
												sera conservé dans les systèmes informatiques de
												l’éditeur du site une preuve de la transaction
												comprenant le bon de commande et la facture.
											</p>
											<p>
												Le responsable du traitement conservera dans ses
												systèmes informatiques du site et dans des
												conditions raisonnables de sécurité l’ensemble des
												données collectées pour une durée de :
											</p>
											<ul>
												<li>données comptables : 10 ans</li>
												<li>inscriptions : 2 ans</li>
											</ul>
											<p>
												cookies d’analyse des visites 13 mois.
												<br />
												La collecte et le traitement des données répondent
												aux finalités suivantes :
											</p>
											<p>
												Abonnement à un service de cours en ligne Abonnement
												à une mailing list
											</p>
											<p>
												Les traitements de données effectués sont fondés sur
												les bases légales suivantes : Consentement de
												l’utilisateur
												<br />
												B. TRANSMISSION DES DONNÉES A DES TIERS
											</p>
											<p>
												Les données à caractère personnel collectées par le
												site ne sont transmises à aucun tiers, et ne sont
												traitées que par l’éditeur du site.
											</p>
										</div>
									</div>
								</div>
								<div className="page" title="Page 4">
									<div className="layoutArea">
										<div className="column">
											<p>
												C. HÉBERGEMENT DES DONNÉES
												<br />
												Le site dourous.net est hébergé par : Firebase, dont
												le siège est situé à l’adresse ci-après :<br />
												188 King ST, San Francisco, CA 94107, United States
											</p>
											<p>&nbsp;</p>
											<p>
												Les données collectées et traitées par le site sont
												exclusivement hébergées et traitées en France.
											</p>
											<p>
												<strong>
													ARTICLE 4 : RESPONSABLE DU TRAITEMENT DES
													DONNÉES A. LE RESPONSABLE DU TRAITEMENT DES
													DONNÉES
												</strong>
											</p>
											<p>
												Le responsable du traitement des données à caractère
												personnel est : Nader Abou Anas. Il peut être
												contacté de la manière suivante :
											</p>
											<p>01.48.31.50.71 de 9h à 12h et de 14h à 18h</p>
											<p>
												Le responsable du traitement des données est chargé
												de déterminer les finalités et les moyens mis au
												service du traitement des données à caractère
												personnel.
											</p>
											<p>
												B. OBLIGATIONS DU RESPONSABLE DU TRAITEMENT DES
												DONNÉES
											</p>
											<p>
												Le responsable du traitement s’engage à protéger les
												données à caractère personnel collectées, à ne pas
												les transmettre à des tiers sans que l’utilisateur
												n’en ait été informé et à respecter les finalités
												pour lesquelles ces données ont été collectées.
											</p>
											<p>
												Le site dispose d’un certificat SSL afin de garantir
												que les informations et le transfert des données
												transitant par le site sont sécurisés.
											</p>
											<p>
												Un certificat SSL (“Secure Socket Layer”
												Certificate) a pour but de sécuriser les données
												échangées entre l’utilisateur et le site.
											</p>
											<p>
												De plus, le responsable du traitement des données
												s’engage à notifier l’utilisateur en cas de
												rectification ou de suppression des données, à moins
												que cela n’entraîne pour lui des formalités, coûts
												et démarches disproportionnés.
											</p>
											<p>
												Dans le cas où l’intégrité, la confidentialité ou la
												sécurité des données à caractère personnel de
												l’utilisateur est compromise, le responsable du
												traitement s’engage à informer l’utilisateur par
												tout moyen.
											</p>
										</div>
									</div>
								</div>
								<div className="page" title="Page 5">
									<div className="layoutArea">
										<div className="column">
											<p>
												<strong>ARTICLE 5 : DROITS DE L’UTILISATEUR</strong>
											</p>
										</div>
									</div>
									<div className="layoutArea">
										<div className="column">
											<p>
												Conformément à la réglementation concernant le
												traitement des données à caractère personnel,
												l’utilisateur possède les droits ci-après énumérés.
											</p>
											<p>
												Afin que le responsable du traitement des données
												fasse droit à sa demande, l’utilisateur est tenu de
												lui communiquer : ses prénom et nom ainsi que son
												adresse e-mail, et si cela est pertinent, son numéro
												de compte ou d’espace personnel ou d’abonné.
											</p>
											<p>
												Le responsable du traitement des données est tenu de
												répondre à l’utilisateur dans un délai de 30
												(trente) jours maximum.
											</p>
											<p>
												A. PRÉSENTATION DES DROITS DE L’UTILISATEUR EN
												MATIÈRE DE COLLECTE ET TRAITEMENT DE DONNÉES
											</p>
											<p>
												a. Droit d’accès, de rectification et droit à
												l’effacement
											</p>
											<p>
												L’utilisateur peut prendre connaissance, mettre à
												jour, modifier ou demander la suppression des
												données le concernant, en respectant la procédure
												ci-après énoncée :
											</p>
											<p>
												Utiliser le lien de désinscription disponible dans
												chaque e-mail envoyé à l’utilisateur
											</p>
											<p>
												S’il en possède un, l’utilisateur a le droit de
												demander la suppression de son espace personnel en
												suivant la procédure suivante :
											</p>
											<p>
												L’utilisateur doit envoyer un e-mail au responsable
												du traitement en précisant son numéro d’espace
												personnel, la demande sera traitée dans un délai de
												10 jours ouvrés
											</p>
											<p>b. Droit à la portabilité des données</p>
											<p>
												L’utilisateur a le droit de demander la portabilité
												de ses données personnelles, détenues par le site,
												vers un autre site, en se conformant à la procédure
												ci-après :
											</p>
											<p>________</p>
											<p>
												c. Droit à la limitation et à l’opposition du
												traitement des données
											</p>
										</div>
									</div>
								</div>
								<div className="page" title="Page 6">
									<div className="layoutArea">
										<div className="column">
											<p>
												L’utilisateur a le droit de demander la limitation
												ou de s’opposer au traitement de ses données par le
												site, sans que le site ne puisse refuser, sauf à
												démontrer l’existence de motifs légitimes et
												impérieux, pouvant prévaloir sur les intérêts et les
												droits et libertés de l’utilisateur.
											</p>
											<p>
												Afin de demander la limitation du traitement de ses
												données ou de formuler une opposition au traitement
												de ses données, l’utilisateur doit suivre la
												procédure suivante :
											</p>
											<p>
												L’utilisateur doit faire une demande de limitation
												au traitement de ces données personnelles par e-mail
												auprès du responsable de traitement des données
											</p>
											<p>
												d. Droit de ne pas faire l’objet d’une décision
												fondée exclusivement sur un procédé automatisé
											</p>
											<p>
												Conformément aux dispositions du règlement 2016/679,
												l’utilisateur a le droit de ne pas faire l’objet
												d’une décision fondée exclusivement sur un procédé
												automatisé si la décision produit des effets
												juridiques le concernant, ou l’affecte de manière
												significative de façon similaire.
											</p>
											<p>
												e. Droit de déterminer le sort des données après la
												mort
											</p>
											<p>
												Il est rappelé à l’utilisateur qu’il peut organiser
												quel doit être le devenir de ses données collectées
												et traitées s’il décède, conformément à la loi
												n°2016-1321 du 7 octobre 2016.
											</p>
											<p>
												f. Droit de saisir l’autorité de contrôle compétente
											</p>
											<p>
												Dans le cas où le responsable du traitement des
												données décide de ne pas répondre à la demande de
												l’utilisateur, et que l’utilisateur souhaite
												contester cette décision, ou, s’il pense qu’il est
												porté atteinte à l’un des droits énumérés ci-dessus,
												il est en droit de saisir la CNIL (Commission
												Nationale de l’Informatique et des Libertés,
												https://www.cnil.fr) ou tout juge compétent.
											</p>
											<p>B. DONNÉES PERSONNELLES DES PERSONNES MINEURES</p>
											<p>
												Conformément aux dispositions de l’article 8 du
												règlement européen 2016/679 et à la loi Informatique
												et Libertés, seuls les mineurs âgés de 15 ans ou
												plus peuvent consentir au traitement de leurs
												données personnelles.
											</p>
											<p>
												Si l’utilisateur est un mineur de moins de 15 ans,
												l’accord d’un représentant légal
											</p>
										</div>
									</div>
								</div>
								<div className="page" title="Page 7">
									<div className="layoutArea">
										<div className="column">
											<p>
												sera requis afin que des données à caractère
												personnel puissent être collectées et traitées.
											</p>
											<p>
												L’éditeur du site se réserve le droit de vérifier
												par tout moyen que l’utilisateur est âgé de plus de
												15 ans, ou qu’il aura obtenu l’accord d’un
												représentant légal avant de naviguer sur le site.
											</p>
											<p>
												<strong>
													ARTICLE 6 : CONDITIONS DE MODIFICATION DE LA
													POLITIQUE DE CONFIDENTIALITÉ
												</strong>
											</p>
											<p>
												La présente politique de confidentialité peut être
												consultée à tout moment à l’adresse ci-après
												indiquée :
											</p>
											<p>https://dourous.net/politique-de-confidentialite</p>
											<p>
												L’éditeur du site se réserve le droit de la modifier
												afin de garantir sa conformité avec le droit en
												vigueur.
											</p>
											<p>
												Par conséquent, l’utilisateur est invité à venir
												consulter régulièrement cette politique de
												confidentialité afin de se tenir informé des
												derniers changements qui lui seront apportés.
											</p>
											<p>
												Toutefois, en cas de modification substantielle de
												cette politique, l’utilisateur en sera informé de la
												manière suivante :
											</p>
											<p>
												Par notification push lors d’une visite de
												l’utilisateur
											</p>
											<p>
												Il est porté à la connaissance de l’utilisateur que
												la dernière mise à jour de la présente politique de
												confidentialité est intervenue le : 08/05/2021.
											</p>
											<p>
												<strong>
													ARTICLE 7 : ACCEPTATION PAR L’UTILISATEUR DE LA
													POLITIQUE DE CONFIDENTIALITÉ
												</strong>
											</p>
											<p>
												En naviguant sur le site, l’utilisateur atteste
												avoir lu et compris la présente politique de
												confidentialité et en accepte les conditions, en ce
												qui concerne plus particulièrement la collecte et le
												traitement de ses données à caractère personnel.
											</p>
										</div>
									</div>
								</div>
							</div>
						</ContentLeft>
					</StickyBox>

					<StickyBox
						offsetTop={20}
						offsetBottom={20}
						className="w-full lg:w-4/12 stickyBox"
					>
						<ContentRight>
							<SideBar modCategoriesDisplay={false} />
						</ContentRight>
					</StickyBox>
				</ContainerWithSideBarre>
			</Container>
		</Layout>
	);
};

export default Politique;
